import React, { useState } from "react";
import {
  Input,
  Form,
  Select,
  notification,
  Steps,
  Upload,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import { FileImageOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSignUpDriverMutation } from "../../../GraphQL/hooks/useSignUpDriverMutation";
import { AllCarModels, allColorOptions, TaxiDriverInput } from "../../../shared/types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ReCAPTCHA from "react-google-recaptcha";

const steps = [
  {
    title: '',
    content: 'Personal Information',
    description1: 'Only your first name and vehicle information are visible to customers when booking',
  },
  {
    title: '',
    content: 'Legal Details',
    description1: 'Your national ID and license details will be kept private.',
  },
  {
    title: '',
    content: 'Documents',
    description1: 'We are legally required to ask for some documents to sign up as a driver.',
    description2: 'Documents scans and qualify photos are accepted.',
  },
  /* {
    title: 'Payment Details',
    content: 'We need your payment details to pay you.',
    description: 'We need your payment details to pay you.',
  },*/
];

const attachmentDocumentsRequested = [
  { label: "CIN Card (Front)", name: "idCardFront" },
  { label: "CIN Card (Back)", name: "idCardBack" },
  { label: "Driver's License", name: "driverLicence" },
  { label: "Professional Card", name: "professionalCard" },
  { label: "Gray Card (Front)", name: "grayCardFront" },
  { label: "Gray Card (Back)", name: "grayCardBack" },
  { label: "Insurance", name: "insurance" },
]

export const InscriptionTaxiDriver = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [driverData, setDriverData] = useState<TaxiDriverInput>();
  const [recaptchaState, setRecaptchaState] = useState(false);

  const { mutation, loading } = useSignUpDriverMutation();

  const [attachments, setAttachments] = useState({
    driverPhoto: null,
    idCardFront: null,
    idCardBack: null,
    driverLicence: null,
    professionalCard: null,
    grayCardFront: null,
    grayCardBack: null,
    insurance: null,
  });

  const updateAttachment = (key, file) => {
    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      [key]: file
    }));
  };


  const handleNext = async () => {
    try {
      const values = await form.validateFields();
      const files = Object.values(attachments)
        .flatMap(attachment => attachment)
        .filter(file => file);

      setDriverData(prev => ({ ...prev, ...values }))
      if (currentStep === steps.length - 1 && recaptchaState) {
        mutation(driverData, files)
          .then((result) => {
            notification.success({
              message: "Success!",
              description: "You have successfully signed up. We will contact you shortly.",
            });
            navigate(`/welcome`);
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: "Failed to sign up. Please try again later.",
            });
            navigate(`/register-taxi-driver`);
          })
          .finally(() => {
            form.resetFields();
            setCurrentStep(0);
            setAttachments(null);
          })

      } else {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      notification.error({
        message: "Validation Error",
        description: "Please fill out the form correctly before proceeding.",
      });
    }
  };


  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };


  const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 16px',
  };

  const formStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const dateYear = (current) => {
    const currentYear = new Date().getFullYear();
    return current && current.year() > currentYear;
  };

  const dateBirthYear = (current) => {
    const currentDate = new Date();
    const cutoffDate = new Date(
      currentDate.getFullYear() - 17,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return current && current.isAfter(cutoffDate, "day");
  };
  const startMonth = dayjs().subtract(17, "year").startOf("year");

  const validateFullName = (_, value) => {
    if (!value || /^[a-zA-Z\s]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Name can only contain letters and spaces.'));
  };

  const validatePhoneNumber = (_, value) => {
    const phoneNumberRegex = /^[0-9]{8}$/; // Example regex for 8-digit numbers
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid phone number format!'));
  };

  return (
    <div style={contentStyle}>
      <div style={{ color: 'black', fontWeight: 'semi-bold', fontSize: '25px' }}>
        {steps[currentStep].content}
      </div>
      <div style={{ color: 'grey', marginTop: '16px' }}>
        {steps[currentStep].description1}
      </div>
      <div style={{ color: 'grey', marginTop: '8px', marginBottom: '8px' }}>
        {steps[currentStep].description2}
      </div>
      <Steps current={currentStep} items={steps} responsive={false} style={{ marginBottom: '24px' }} />
      <Form form={form} layout="vertical" style={formStyle}>
        {currentStep === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <Form.Item
                  name="fullName"
                  label="Full name"
                  rules={
                    [{ required: true, message: "Please input your full name!" }, { validator: validateFullName }]
                  }

                >
                  <Input placeholder="Enter your full name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="birthday"
                  label="Birthday"
                  rules={[{ required: true, message: "Please select your birthday!" }]}
                >
                  <DatePicker disabledDate={dateBirthYear} defaultPickerValue={startMonth} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[{ type: 'email', required: true, message: 'Please input your e-mail address!' }]}
                >
                  <Input placeholder="Enter your e-mail" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone number"
                  rules={[{ required: true, message: 'Please input your phone number!' }, { validator: validatePhoneNumber }]}
                >
                  <Input placeholder="Enter your phone number" type="tel" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ReferralCode"
                  label="Referral code"
                  rules={[{ required: false }]}
                >
                  <Input
                    type="tel"
                    placeholder="Referral code"
                    suffix={
                      <Tooltip title="If someone referred you, enter their code" placement="rightTop">
                        <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="marque"
                  label="Vehicle model:"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select model of your vehicle" showSearch allowClear>
                    {(AllCarModels ?? []).map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="immatriculation"
                  label="License plate:"
                  rules={[
                    { required: true, message: 'Please input the license plate!' },
                    {
                      pattern: /^[0-9]{3} Tunis [0-9]{4}$/,
                      message: 'The license plate format should be "123 Tunis 4567".',
                    },
                  ]}
                >
                  <Input
                    placeholder="123 Tunis 4567"
                    onChange={(e) => {
                      let value = e.target.value;
                      // Insert "Tunis" after the first 3 digits if it's not already there
                      if (/^\d{3}(?! Tunis)/.test(value)) {
                        value = value.replace(/^(\d{3})(.*)/, "$1 Tunis$2");
                      }
                      // Ensure there is exactly one space after "Tunis"
                      value = value.replace(/Tunis\s*/, "Tunis ");
                      // Limit to the correct format: 3 digits + "Tunis" + up to 4 digits
                      value = value.replace(/^(\d{3} Tunis \d{0,4}).*/, "$1");
                      form.setFieldsValue({ immatriculation: value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="color"
                  label="Vehicle color:"
                  rules={[{ required: true, message: 'Please input the vehicle color!' }]}
                >
                  <Select placeholder="Select color of your vehicle" showSearch allowClear >
                    {(allColorOptions ?? []).map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="year"
                  label="Vehicle registration year:"
                  rules={[{ required: true, message: 'Please enter the year of first registration of the vehicle!' }]}
                >
                  <DatePicker picker="year" disabledDate={dateYear} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Profile photo"
                  rules={[{ required: true, message: "Please upload your profile picture." }]}
                  style={{ display: 'inline-block' }}
                  labelCol={{ style: { order: 2, textAlign: 'center' } }}
                >
                  <Upload
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={({ file }) => updateAttachment('driverPhoto', file)}
                    showUploadList={false}
                    listType="picture-card"
                  >
                    {attachments["driverPhoto"]?.size > 0 ? (
                      <div>
                        <FileImageOutlined />
                        <span style={{ display: 'inline-block', maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {attachments["driverPhoto"].name}
                        </span>
                      </div>
                      ) : (
                      <div style={{ textAlign: 'center' }}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload image</div>
                      </div>
                    )}

                  </Upload>
                </Form.Item>
              </Col>

            </Row>
          </div>
        )}

        {currentStep === 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <Form.Item
                  name="idCard"
                  label="National ID"
                  rules={[
                    { required: true, message: "Please input your national ID!" },
                  ]}
                >
                  <Input
                    placeholder="Enter your national ID"
                    maxLength={8}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      form.setFieldsValue({ idCard: value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="licenseNumber"
                  label="License number"
                  rules={[{ required: true, message: "Please input your license number!" }]}
                >
                  <Input
                    placeholder="Enter your license number"
                    maxLength={8}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^\d{2}(?!\/)/.test(value)) {
                        value = value.replace(/^(\d{2})/, "$1/");
                      }
                      // Limit the length to exactly 2 digits + '/' + 6 digits
                      if (/^\d{2}\/\d*/.test(value)) {
                        value = value.slice(0, 9); // Max length: 2 digits + '/' + 6 digits
                      }
                      form.setFieldsValue({ licenseNumber: value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        {currentStep === 2 && (
          <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              {attachmentDocumentsRequested.map((item, index) => (
                <Col span={8} key={index}>
                  <Form.Item
                    labelCol={{ style: { order: 2, textAlign: 'center' } }}
                    name={item.name}
                    label={item.label}
                    valuePropName={item.name}
                    getValueFromEvent={(event) => event?.file}
                    rules={[{ required: true, message: `Please upload your ${item.label.toLowerCase()}!` }]}
                  >
                    <Upload
                      accept="image/png, image/jpeg"
                      maxCount={1}
                      beforeUpload={() => false}
                      onChange={({ file }) => updateAttachment(item.name, file)}
                      showUploadList={false}
                      listType="picture-card"
                    >
                      {attachments[item.name]?.size > 0 ? (
                        <div>
                          <FileImageOutlined />
                          <span style={{ display: 'inline-block', maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {attachments[item.name].name}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload image</div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </div>
            <RecaptchaWrapper style={{display: 'flex', justifyContent: 'center', paddingBottom: '20px'}}>
              <ReCAPTCHA 
                onChange={() => setRecaptchaState(true)}
                onExpired={() =>setRecaptchaState(false)}
                onErrored={() =>setRecaptchaState(false)}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              />
            </RecaptchaWrapper>
          </>
        )}

        <Form.Item >
          <ButtonWrapper>
            <button
              className="animate radius8 NextInput semiBold"
              onClick={handleNext}
              disabled={loading || (currentStep < 2 ? recaptchaState : !recaptchaState)}
              style={(currentStep < 2 ? recaptchaState : !recaptchaState) ? { backgroundColor: 'lightgrey' } : { }}
              > 
              {currentStep === steps.length - 1 ? "Finish" : "Next"}
            </button>
          </ButtonWrapper>

          {currentStep > 0 && (
            <ButtonWrapper>
              <button
                className="animate radius8 semiBold PreviousInput"
                onClick={handlePrev}
                disabled={loading}
              >
                Previous
              </button>
            </ButtonWrapper>
          )}
        </Form.Item>


      </Form>
    </div>
  );
};

export default InscriptionTaxiDriver;
const RecaptchaWrapper = styled.div`
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px; 

   & > button {
    margin-right: 10px; 
  }

  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

