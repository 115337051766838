import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Menu, Dropdown, Grid, Divider } from 'antd';
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import LogoImg from "../../assets/img/CITRIN-Logo.png";
import FRImg from "../../assets/img/fr.png";
import ENImg from "../../assets/img/en.png";
import {  useLocation } from 'react-router-dom';

import BurgerIcon from "../../assets/svg/BurgerIcon";
import { useTranslation } from "react-i18next";
const { useBreakpoint } = Grid;
import { GlobalOutlined } from '@ant-design/icons';

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [isWelcomePage, setIsWelcomePage] = useState(true);
  const screens = useBreakpoint();
  const isSamllScreen = screens.xs; // Adjust margin for small screens
  const location = useLocation();


  useEffect(() => {
    if(location.pathname === "/welcome") {
      setIsWelcomePage(true)
    } else {
      setIsWelcomePage(false)
    }

    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en_US" ? "fr_FR" : "en_US";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }

  const languageMenu = [
    {
      key: '0',
      label:(<a onClick={() => handleChangeLanguage("en_US")}>
                 {isSamllScreen ? t('en') : t('english')}
             </a>),
      icon: <img src={ENImg} width={20} height={15} alt="English" />
    },
    {
      key: '1',
      label: (<a onClick={() => handleChangeLanguage("fr_FR")}>
                {isSamllScreen ? t('fr') : t('francais')}
              </a>),
      icon: <img src={FRImg} width={20} height={15} alt="Français" />
    }
  ];

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <a className="pointer flexNullCenter" href="welcome" >
            <img src={LogoImg} alt="citrin" width="50" height="50" />
            <h2 className="blackColor font20" style={{ marginLeft: "10px" }}>
              CITRIN. Mobility
            </h2>
          </a>

          <LanguageWrapper>
            <Dropdown menu={{ items: languageMenu }} trigger={['click']}>
              <LanguageButton className="radius8 whiteBg" >
                <IconWrapper>
                  <GlobalOutlined />
                </IconWrapper>
                {currentLanguage === "en_US" ? t('en') : t('fr')}
              </LanguageButton>
            </Dropdown>
          </LanguageWrapper>

          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter" style={isWelcomePage ? {display: ''} : {display: 'none'}} >
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                {t('home')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="citrinVision" spy={true} smooth={true} offset={-80}>
                {t('citrinVision')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="b2b" spy={true} smooth={true} offset={-80}>
                {t('b2b')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="sustainablity" spy={true} smooth={true} offset={-80}>
                {t('sustainability')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="partners" spy={true} smooth={true} offset={-80}>
                {t('partners')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                {t('contact')}
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <Dropdown menu={{ items: languageMenu }} trigger={['click']}>
                <LanguageButton className="radius8 whiteBg" style={{ padding: "10px 15px" }}>
                  <IconWrapper>
                    <GlobalOutlined />
                  </IconWrapper>
                  {currentLanguage === "en_US" ? t('english') : t('francais')}
                </LanguageButton>
              </Dropdown>
            </li>
            <li className="semiBold font15 pointer">
              <a href="login" style={{ padding: "10px 30px 10px 0" }}>
                Log-in B2B
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="https://play.google.com" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                {t('getTheApp')}
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const LanguageButton = styled.button`
  background: transparent;
  border: 1px solid #B8B4B5;
  font-size: 15px;
  cursor: pointer;
  padding: 10px 15px;
  margin-right: 20px;
  position: relative;
  &:hover {
    background: #f0f0f0;
  }

  @media (max-width: 760px) {
    padding: 9px 15px;
    margin-right: 0px;
    display: flex;
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px; 
`;



const LanguageWrapper = styled.div`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  display: none;
  @media (max-width: 760px) {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;