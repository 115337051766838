import { Button, Col, Pagination, Row, Space, Spin, Table, Tooltip } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { useSortedRegisterTaxiDriverQuery } from '../hooks/useSortedRegisterTaxiDriverQuery';
import { useCurrentRegisterTaxiDriver } from '../hooks/useCurrentRegisterTaxiDriver';
import RegisterTaxiDriverViewModal from './RegisterTaxiDriverViewModal';
import { RegisterTaxiDriverFilter } from './RegisterTaxiDriverFilter';
import { TaxiDriver } from '../../../shared/types';
import RegisterTaxiDriverEditModal from './RegisterTaxiDriverEditModal';
import moment from 'moment';


const RegisterTaxiDriverTable = () => {
   
  const { sortedRegisterTaxiDrivers: taxiDrivers, refetch, loading, ...pagination } = useSortedRegisterTaxiDriverQuery();
  const [isViewModalVisible, setViewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const { setCurrentRegisterTaxiDriver } = useCurrentRegisterTaxiDriver();


  const handleEditTaxiDriver = (registerTaxiDriver: TaxiDriver) => {
    setCurrentRegisterTaxiDriver(registerTaxiDriver);
    setEditModalVisible(true);
};

  const handleViewRegisterTaxiDriver = (registerTaxiDriver) => {
    setCurrentRegisterTaxiDriver(registerTaxiDriver);
      setViewModalVisible(true);
  };

    const [IsLoading, setIsLoading] = useState(false);

    const renderRegisterStatus = (text: any, record: TaxiDriver) => {
        switch (record.registerStatus) {
            case 'Pending':
                return (
                    <Tooltip title="Pending">
                        <ExclamationCircleOutlined style={{ fontSize: '16px', color: 'red' }} />
                    </Tooltip>
                );
            case 'Confirmed':
                return (
                    <Tooltip title="Confirmed">
                        <CheckCircleOutlined style={{ fontSize: '16px', color: 'green' }} />
                    </Tooltip>
                );
            case 'In progress':
                return (
                    <Tooltip title="In progress">
                        <ExclamationCircleOutlined style={{ fontSize: '16px', color: 'orange' }} />
                    </Tooltip>
                );
            default:
                return null;
        }
    };
    
    const columns = [

        {
            title: 'Fullname',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',

        },
        {
            title: 'Id Card',
            dataIndex: 'idCard',
            key: 'idCard',

        },
        {
            title: 'Marque',
            dataIndex: 'marque',
            key: 'marque',
        },
        {
            title: 'Register Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a, b) => a.createdDate - b.createdDate,
            render: (text, record) => { return moment(Number(record.createdDate)).format("DD-MM-YYYY"); }
        },
        {
            title: 'Register Status',
            dataIndex: 'registerStatus',
            key: 'registerStatus',
            render: renderRegisterStatus,

        },
        {
            title: 'Account Status',
            dataIndex: 'accountStatus',
            key: 'accountStatus',
            render: (text, record: TaxiDriver) => { return record.accountStatus ? <CheckCircleOutlined title='Active' style={{ fontSize: '16px', color: 'green' }} /> : <ExclamationCircleOutlined  title='Inactive' style={{ fontSize: '16px', color: 'red' }} /> }

        },
        {
            title: "Action",
            key: "action",
            render: (_, registerTaxiDriver) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleViewRegisterTaxiDriver(registerTaxiDriver)} ><EyeOutlined /></a>
                    <a href="#" onClick={() => handleEditTaxiDriver(registerTaxiDriver)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                </Space>
          
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Taxi Driver ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );

    return (
        <>
           {isEditModalVisible && <RegisterTaxiDriverEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}
           {isViewModalVisible && <RegisterTaxiDriverViewModal isOpen={isViewModalVisible} onRequestClose={setViewModalVisible} />}


           <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <RegisterTaxiDriverFilter />
           </Row>

            <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col xs={24}>
                    <Spin spinning={IsLoading || loading}>
                        <Table
                            dataSource={taxiDrivers}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                    </Spin>
                </Col>
            </Row>
            <Paginator />
        </>
    )
};


export default RegisterTaxiDriverTable;