// Components
import React from "react";
import styled from "styled-components";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

import GooglePlayImg from "../../assets/img/google-play.png";
import AppStoreImg from "../../assets/img/app-store.png";

import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation();

  
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">{t('Slogan')}</h1>
          <HeaderP className="font14 semiBold">
          {t('textSlogan')} 
          </HeaderP>
          <ButtonContainer>
            <BtnWrapper>
              <a className="pointer flexNullCenter flexCenter" href="https://play.google.com/store/apps?hl=fr&gl=US"  target="_blank">
                <img src={GooglePlayImg} alt="facebook-Citrin" style= {{ maxWidth: '220px' }} />
              </a>
            </BtnWrapper>

            <BtnWrapper>
              <a className="pointer flexNullCenter flexCenter" href="https://www.apple.com/fr/store"  target="_blank">
                <img src={AppStoreImg} alt="instagram-Citrin" style= {{ maxWidth: '220px' }} />
              </a>
            </BtnWrapper>
          </ButtonContainer>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9 }} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>“Mobility is freedom and it is in our human nature to be free”</em>
              </p>
              <p className="font13 orangeColor textRight" style={{ marginTop: '10px' }}>David Reed - Founder & CEO at SustainMobility </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;

  /* Shadow Effect */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); 

  @media (max-width: 560px) {
    width: 80%;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 109px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -17px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px; 
  @media (max-width: 560px) {
    display: block;
    gap: 0px; 
  }
`;


