import React, { useMemo } from 'react';
import { Col, DatePicker, Input, Radio, Select, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useOverviewLinkStateActions } from '../hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from '../linkstate/RegisterTaxiDriverOverview.linkstate';

export type AccountStatus = true | false;
export const allAccountStatus: AccountStatus[] = [true, false];

export type RegisterStatus = 'Pending' | 'In progress' | 'Confirmed';
export const allRegisterStatus: RegisterStatus[] = ['Pending', 'In progress', 'Confirmed'];

export const RegisterTaxiDriverFilter = () => {
    const isNotEmpty = (s: string) => s && !isEmpty(s);

    const [{ search, selectedRegisterStatus, accountStatus, selectedDateRange }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    const rangePickerValue = useMemo(() => {
        return isNotEmpty(selectedDateRange?.fromDate) && isNotEmpty(selectedDateRange?.toDate)
            ? [moment(selectedDateRange?.fromDate), moment(selectedDateRange?.toDate)]
            : [null, null];
    }, [selectedDateRange]);

    const handleRangePickerChanged = (dates: [any, any]) => {
        patchState({
            selectedDateRange:
                dates && dates[0] && dates[1]
                    ? {
                        fromDate: dates[0].format('YYYY-MM-DD'),
                        toDate: dates[1].format('YYYY-MM-DD'),
                    }
                    : undefined,
        });
    };

    return (
        <>
            <Col style={{ width: 350 }}>
                <Input
                    value={search}
                    placeholder={'Search by email, fullName, phone..'}
                    onChange={(e) => patchState({ search: e.target.value })}
                    addonBefore={<SearchOutlined />}
                />
            </Col>
            <Col style={{ width: 200 }}>
                <Tooltip title={`Filter by Type: ${(allRegisterStatus ?? []).map((type) => `#${type}`).join(' ')}`}>
                    <Select
                        placeholder="Select register status"
                        mode="multiple"
                        value={selectedRegisterStatus ?? []}
                        style={{ width: '100%', height: 32 }}
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(value) => patchState({ selectedRegisterStatus: value })}
                    >
                        {(allRegisterStatus ?? []).map((type) => (
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title={`Filter by account status`}>
                    <Radio.Group
                        value={accountStatus}
                        buttonStyle="solid"
                        style={{ width: '100%' }}
                        onChange={(e) => patchState({ accountStatus: e.target.value })}
                    >
                        {allAccountStatus.map((v) => (
                            <Radio.Button key={`statusFilter-${v}`} value={v}>
                                {v ? "Active" : "Inactive"}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title={`Filter by register date`}>
                    <DatePicker.RangePicker value={rangePickerValue as any} onChange={handleRangePickerChanged} />
                </Tooltip>
            </Col>
        </>
    );
};
