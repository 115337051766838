import { useEffect, useMemo } from "react";
import { TaxiDriver, TaxiDriverQueryFilterMGT } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/RegisterTaxiDriverOverview.linkstate";
import { useGetRegisterTaxiDriverQuery } from "../../../GraphQL/hooks/useGetRegisterTaxiDriverQuery";

const pageSize = 10;
const getPageCount = (TaxiDrivers: TaxiDriver[]) => Math.ceil(TaxiDrivers?.length / pageSize);
const getCurrentPage = (requestedPage: number, TaxiDrivers: TaxiDriver[]) => {
    const pageCount = getPageCount(TaxiDrivers);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedRegisterTaxiDriverQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page, search, selectedRegisterStatus, accountStatus, selectedDateRange } = state;

    const request = useMemo(
    
        () =>
        ({
            search: search ?? '',
            registerStatus: isEmpty(selectedRegisterStatus) ? undefined : selectedRegisterStatus,
            accountStatus: accountStatus,
            dateRange: selectedDateRange,
        } as TaxiDriverQueryFilterMGT),

        [search, selectedRegisterStatus, accountStatus, selectedDateRange ]
        
    );

    const { data: unsortedRegisterTaxiDrivers, refetch, loading } = useGetRegisterTaxiDriverQuery(request);
    useEffect(() => setLoading(loading), [loading]);
    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedRegisterTaxiDrivers);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedRegisterTaxiDrivers } = useMemo(() => {
        const totalCount = unsortedRegisterTaxiDrivers?.length ?? 0;
        const pageCount = getPageCount(unsortedRegisterTaxiDrivers);
        const sortedRegisterTaxiDrivers = chunk(unsortedRegisterTaxiDrivers, pageSize);

        return { totalCount, pageCount, sortedRegisterTaxiDrivers };
    }, [unsortedRegisterTaxiDrivers]);

    const currentPage = getCurrentPage(page, unsortedRegisterTaxiDrivers);

    return {
        sortedRegisterTaxiDrivers: sortedRegisterTaxiDrivers?.length > 0 ? sortedRegisterTaxiDrivers[currentPage - 1] : [],
        refetch: () => refetch({request}),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
   

