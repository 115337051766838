import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import RegisterTaxiDriverTable from './Components/RegisterTaxiDriverTable';
import { RegisterTaxiDriverInfoProvider } from './hooks/useCurrentRegisterTaxiDriver';

const TaxiDriversView = () => {

    return (
        <>
            <>
                <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col span={12}><Title level={3}>Taxi Drivers</Title></Col>
                </Row>

                <div style={{ flex: '1 1 auto' }}>
                    <RegisterTaxiDriverInfoProvider>
                        <RegisterTaxiDriverTable />
                    </RegisterTaxiDriverInfoProvider>
                </div>
            </>
        </>
    )
};

export default TaxiDriversView;