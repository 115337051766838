import { useQuery } from '@apollo/client';
import { CITRIN_GET_REGISTER_TAXI_DRIVER, GetRegisterTaxiDriverRequest, GetRegisterTaxiDriverResponse } from '..';
import { TaxiDriverQueryFilterMGT } from '../../shared/types';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

export const useGetRegisterTaxiDriverQuery = (request: TaxiDriverQueryFilterMGT, debounceTime?: number) => {
    const [internalRequest, setInternalRequest] = useState(() => request);
    const debounced = useRef(
        debounce(
            (newRequest:TaxiDriverQueryFilterMGT) => {
                setInternalRequest(newRequest);
            },
            debounceTime ?? 100,
            { leading: false, trailing: true }
        ) 
    ).current;
    useEffect(() => {
        console.log(request)

        return debounced(request);
    }, [request]);
   
   
    const { data, previousData, ...rest } = useQuery<GetRegisterTaxiDriverResponse, GetRegisterTaxiDriverRequest>(CITRIN_GET_REGISTER_TAXI_DRIVER, {
        variables: { request: internalRequest },
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Pricing', data),
    });

    return { ...rest, previousData: previousData?.CITRIN_MGT_getAllTaxiDrivers, data: data?.CITRIN_MGT_getAllTaxiDrivers ?? [] };
};