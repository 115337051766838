import React, { useState } from "react";
import styled from "styled-components";
import { Collapse } from 'antd';
import TopNavbar from "../../Nav/TopNavbar";
import Footer from "../Footer";
import AssitantBox from "../../../components/Elements/AssitantBox";
import UserImg1 from "../../../assets/img/user.png";
import WalletImg2 from "../../../assets/img/wallet.png";
import QuestionImg3 from "../../../assets/img/question.png";
import ParkingImg4 from "../../../assets/img/parking.png";
import ProtectionImg5 from "../../../assets/img/protection.png";
import { Link } from "react-scroll";

const { Panel } = Collapse;

const contentMap = {
  '1': [
    <Panel header="How do I start and end an e-scooter ride?" key="1_1">
      <p>1. Find a CITRIN e-scooter near you and scan the QR-code or click on start ride inside the app to unlock it.</p>

      <br />
      <p>2. Push the e-scooter forward to flick the kickstand back. Place one foot on the footboard, hands on the handlebar and push off with your other foot 3-4 times to get going. </p>

      <br />
      <p> 3. Place both feet on the footboard when you're ready to accelerate. Use the right acceleration lever to gain speed. We advise you not to press all the way down your first time riding. </p>

      Please note that the maximum speed of CITRIN e-scooters is 25km/h.

      <br />
      <br />

      <p>4. Let go of the acceleration lever to decrease your speed or gently squeeze the left or right hand brake to slow down even more, and to come to a stop.</p>

      <br />
      <p>5. Use the left and right indicators to signal your movements to other road users.</p>

      <br />
      <p>6. When you’re done, park in a designated parking spot or find a safe place on the pavement away from pedestrians.</p>

      <br />

      <p>7. Pull the CITRIN vehicle back onto its kickstand and tap end ride in the CITRIN app. </p>
    </Panel>,
    <Panel header="What happens if I leave the vehicle unlocked?" key="1_2">
      <p>Once unlocked, the vehicle is under your responsibility and it should never be left unattended. </p>
      <br />
      <p>If you’d like to move away from the vehicle for a moment, simply pause your rental inside the app. The vehicle will then remain locked and reserved for you.</p>
      <br />
      <p>Once you reach your final destination, please wait for the in-app confirmation screen to make sure that the ride has been successfully ended and the vehicle has been locked.</p>
    </Panel>,
    <Panel header="Is there an age limit?" key="1_3">
      <p>You must be at least 18 years of age to use our e-scooters, e-mopeds and e-cars,
        <br />
        and at least 15 years of age to use our e-bikes as per our User Agreement.</p>
    </Panel>
  ],
  '2': [
    <Panel header="How do I sign up?" key="2_1">
      <p>Download the CITRIN app from the Apple or Google Play Store.</p>
      <br />
      <p>Create an account with your Facebook ID, Google Account, or phone number.</p>
      <br />
      <p>Add your payment method details.</p>
      <br />
      <p>Verify your identity card </p>
      <br />
      <p>You’re ready to ride!</p>

    </Panel>,
    <Panel header="How much does it cost to ride a CITRIN?" key="2_2">
      <p>The cost of riding a CITRIN vehicle depends on the payment plan you choose. There are three options: pay-as-you-go, a day pass, and a monthly subscription.</p>

      <p>More information on our pricing can be found directly in the app!</p>
    </Panel>,
    <Panel header="I am not receiving the SMS code." key="2_3">
      <p>When registering in our app with your phone number we will try to send you a confirmation SMS, to make 100% sure that it is indeed you.</p>
      <br />
      <p>If you do not receive the SMS, please make sure: </p>

      <br />
      <p>1. To restart your device, so your connection is refreshed </p>
      <br />
      <p>2. Enter your phone number once more in the CITRIN app. </p>
      <br />
      <p>3. Double check that the number is correct and you have chosen the right area code. </p>
      <br />
      <p>Alternatively, you can also register with us via your Google or Facebook ID. </p>
    </Panel>,
    <Panel header="How do I invite my friends and get credits?" key="2_4">
    <p>You can earn 50% free rides by inviting your friends to join CITRIN using your special link in the app! Here's how:</p>
    <br />
    <h3>Add your payment details:</h3><p> Make sure you've added an active payment method in the app. Without it, you won't get the referral credits.</p>

    <br />
    <h3>Find "Promos and Coupon" in the main menu:</h3><p>Go there to get your unique code </p>
    <br />
    <h3>Share the code with your friend:</h3><p>Send the code to your friend by selecting 'Share now'.
 </p>
    <br />
    <h3>Friend signs up using your link:</h3><p>Your friend needs to download the app through your link and add their payment info to get their CITRIN credits.</p>
    <br />
    <h3>Friend takes a ride:</h3> <p>After using their CITRIN credits, your friend also needs to take a paid ride with CITRIN.</p>
    <br />
    <h3>You get credits:</h3> <p>Once your friend completes a paid ride, you'll receive your credits for referring them!</p>
  </Panel>
  ],
  '3': [
    <Panel header="I can't end my ride." key="3_1">
      <p>Zoom in to your current location on the map and check that you are: </p>
​<br/>
<p>1. Outside a restricted (dark grey colour) area. </p>
<br/>
<p>2. Outside a no-parking (red colour) zone.  </p>
<br/>
<p>Once you've moved the vehicle in a parking zone: </p>
<br/>
<p>1.  Push the kickstand down.  </p>
<br/>
<p>2. Refresh your GPS location by clicking on the arrow button inside the app at the bottom right corner. </p>
<br/>
<p>3. Press the end ride button and make sure that the vehicle locks successfully.  </p>
​<br/>
<p>If you are still receiving any type of error message inside the CITRIN app and are not able to end the ride, please contact us via chat and we will be able to help you. </p>



    </Panel>,
    <Panel header="I forgot to end my ride." key="3_2">
      <p>Please contact us immediately so we can end your ride for you.</p>
      ​<br/>

      <p>If the vehicle didn’t move after you left it, we may be able to refund the time the vehicle stayed still.</p>

      ​<br/>
      <p>Moving forward please be sure to double-check to correctly end the ride.</p>

      ​<br/>
      <p>The vehicle will make a beeping sound and the light will blink to confirm that the ride was correctly turned off.</p>

      ​<br/>
      <p>You will get a confirmation in the app and email after your ride.</p>
    </Panel>,
    
  ],
  '4': [
    <Panel header="Where can I park?" key="4_1">
      <p>When you have reached your destination, doublecheck that: </p>
​ ​<br/>
<p>1. You are inside our service zone (white-off colour). </p>
​<br/>
<p>2. You are not in a no parking zone. (marked as red on the map). </p>
​ ​<br/>
<p>We recommend checking the map before you start your ride, to make sure that your destination address will guarantee effortless parking.</p>
    </Panel>,
    <Panel header="How can I report a wrongly parked vehicle?" key="4_2">
      <p>If you come across a wrongly parked vehicle, please submit a report using the CITRIN app:<br/><small><b>Settings Menu {"->"} Helps security {"->"} Report Problem</b></small></p>
      ​ ​<br/>
      <p>The information you provide in this report will be sent directly to the CITRIN Operations team, enabling us to take prompt action.</p>
    </Panel>,
  ],
  '5': [
    <Panel header="Can I use my phone while riding?" key="5_1">
      <p>You should not use your phone during your ride for your own safety. </p>
​ ​ ​<br/>
<p>Please use the in-app navigation during your rental or step off the vehicle to check your route during the ride.</p>
    </Panel>,
    <Panel header="Can two people ride on the same vehicle?" key="5_2">
      <p>Unfortunately, you can not ride our e-bikes or e-scooters with a passenger.</p>
​
<p>If you want to ride with your friends or family, check our group ride feature, which will allow you to unlock multiple vehicles from one CITRIN account.</p>
    </Panel>,
    <Panel header="How do you handle my user data?" key="5_3">
      <p>We value your privacy, and safeguarding your personal information is a top priority for us. We take data protection seriously because we honour the trust you place in us to handle your personal information appropriately and responsibly.</p>
      ​ ​ ​<br/>
      <p>Explore our <Link className="orangeColor font13 semiBold" to="privacy-policy">privacy policy</Link> for a detailed overview of how we manage your data in accordance with the National authority for the protection of personal data (INPDP), ensuring transparency and compliance.</p>
    </Panel>
  ],
};

export default function HelpCenterView() {
  const [activeKey, setActiveKey] = useState(null);

  const handleBlogBoxClick = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <>
      <TopNavbar />
      <Wrapper id="helpCenter">
        <Container>
          <Content>
            <div className="whiteBg" style={{ padding: "60px 0" }}>
              <Section className="row flexCenter">
                <Title className="extraBold font60">How can we help?</Title>
              </Section>

              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div onClick={() => handleBlogBoxClick('1')}>
                    <AssitantBox imgSrc={UserImg1} title="Getting Started" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div onClick={() => handleBlogBoxClick('2')}>
                    <AssitantBox imgSrc={WalletImg2} title="Account and Payments" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div onClick={() => handleBlogBoxClick('3')}>
                    <AssitantBox imgSrc={QuestionImg3} title="Help with your ride" />
                  </div>
                </div>
              </div>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div onClick={() => handleBlogBoxClick('4')}>
                    <AssitantBox imgSrc={ParkingImg4} title="Parking Guidelines" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div onClick={() => handleBlogBoxClick('5')}>
                    <AssitantBox imgSrc={ProtectionImg5} title="Safetyand T&C's" />
                  </div>
                </div>
              </div>
              <div style={{ padding: "60px 0" }}>
                {activeKey && (
                  <Collapse>
                    {contentMap[activeKey]}
                  </Collapse>
                )}
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Container = styled.div`
  padding: 60px 0;
  background-color: #fff;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  margin-top: 23px;
`;
