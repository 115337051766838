//import { ObjectId } from 'bson';

/* shared*/
export type Address = {
    street?: string,
    postalCode?: number,
    city?: string,
    state?: string,
    country?: string, 
}

export type attachmentsUploaded = {
    uid?: string,
    size?: number,
    name?: string,
    type?: string,
    url?: string, 
}

export type  IdentityCheckStatus = true | false;
export type  VehiculeStatus = true | false;
export type  CouponStatus = true | false;
export type  Calendar = 'Default' | 'This month' | 'This year' | 'Whole time';

export const allIdentityCheckStatus: IdentityCheckStatus[] = [true, false];
export const allVehiculeStatus: VehiculeStatus[] = [true, false];
export const allVehiculeTypes: string[] = ['E-Bike', 'E-Scooter', 'E-Moped', 'E-Cargo', 'E-Car'];
export const allPowerTypes: string[] = ['Solar Dock', 'Removable Battery', 'Power Plug'];
export const allOperatorTypes: string[] = ['Business', 'Partner', 'STASIA Mobility'];
export const allLevelTypes: string[] = ['Primary', 'Secondary'];
export const allPassTypeTypes: string[] = ['Weekend', 'Monthly', 'Daily', 'AnyTime', 'Student'];
export const allCouponTypes: string[] = ['Refund', 'Gift', 'Promo Code'];
export const allCouponsStatus: CouponStatus[] = [true, false];
export const allCalendarsTypes: Calendar[] = ['Default', 'This month', 'This year', 'Whole time'];
export const allVehiculesProblemStatus: string[] = ['Solved', 'InProgress', 'Issue'];
export const allPriceUnitsTypes: string[] = ['Month', 'Day', '3 Months'];

export const allColorOptions: { value: string; label: string }[] = [
    { value: "RED", label: "Red" },
    { value: "BLUE", label: "Blue" },
    { value: "BLACK", label: "Black" },
    { value: "WHITE", label: "White" },
    { value: "SILVER", label: "Silver" },
    { value: "GRAY", label: "Gray" },
    { value: "GREEN", label: "Green" },
    { value: "YELLOW", label: "Yellow" },
    { value: "ORANGE", label: "Orange" },
    { value: "BROWN", label: "Brown" },
    { value: "PURPLE", label: "Purple" },
    { value: "PINK", label: "Pink" },
    { value: "GOLD", label: "Gold" },
    { value: "BEIGE", label: "Beige" },
    { value: "TURQUOISE", label: "Turquoise" },
    { value: "BURGUNDY", label: "Burgundy" },
    { value: "CHARCOAL", label: "Charcoal" },
    { value: "IVORY", label: "Ivory" },
    { value: "CHAMPAGNE", label: "Champagne" },
    { value: "LIME", label: "Lime" }
  ];
  
export const AllCarModels: { value: string; label: string }[] = [
    {  value: "CHEVROLET AVEO", label: "CHEVROLET AVEO"},
    {   value: "CHEVROLET CAPTIVA", label: "CHEVROLET CAPTIVA"},
    { value: "CHEVROLET CHEVY", label: "CHEVROLET CHEVY"},
    {  value: "CHEVROLET CRUZE", label: "CHEVROLET CRUZE"},
    { value: "CHEVROLET EPICA", label: "CHEVROLET EPICA"},
    {  value: "CITROEN BERLINGO", label: "CITROEN BERLINGO"},
    {  value: "CITROEN C-ELYSÉE", label: "CITROEN C-ELYSÉE"},
    { value: "CITROEN C1", label: "CITROEN C1"},
    {  value: "CITROEN C3", label: "CITROEN C3"},
    {  value: "DACIA DOKKER", label: "DACIA DOKKER"},
    {  value: "DACIA	LODGY", label: "DACIA	LODGY"},
    {  value: "FIAT	500L", label: "FIAT	500L"},
    { value: "FIAT	PANDA", label: "FIAT PANDA"},
    {  value: "HYUNDAI	GETZ", label: "HYUNDAI	GETZ"},
    {  value: "HYUNDAI	I10", label: "HYUNDAI I10"},
    {  value: "KIA	OPTIMA", label: "KIA OPTIMA"},
    { value: "HYUNDAI I10", label: "HYUNDAI	I10"},
    {  value: "RENAULT CAPTUR", label: "RENAULT	CAPTUR",},
    { value: "RENAULT CLIO", label: "RENAULT	CLIO"}
  ];
  


/* Rider */

export type Rider = {
    _id: string;
    logicalId: number;
    fullName: string
    email: string
    phoneNumber: string   
    birthday: string
    riderPhoto: RIDER_ATTACHMENT
    currentLocation: RIDER_Location
    isPaymentDefaultMethod: boolean
    paymentMethod: Payment_Method
    isLogged: Boolean
    signingMethod: SigningMethod
    idProvider: string
    privacy: Privacy
    gender: string
    country: string
    idCardMetaData: IdCardMetaData
    idCardPhoto: RIDER_ATTACHMENT
    riderSelfie: RIDER_ATTACHMENT
    identityCheck: boolean
    ridePassId?: RidePass
};

export type RIDER_ATTACHMENT = {
    uid: string,
    size: number,
    name: string,
    type: string,
    url: string,
    isMainImageAttachment: boolean,
};

export type RIDER_Location = {
    latitude: number;
    longitude: number;
    timestamp: number;
};

export type Payment_Method = {
    konnect: boolean;
    ooredooPayment: boolean;
    creditCard: boolean;
};

export type IdCardMetaData = {
    documentName: string;
    ICAOCode: string;
    dCountryName: string;
    dDescription: string;
    dYear: string;
};

export type Privacy = {
    emailMarketing: boolean
    pushNotifications: boolean
    updateFeatures: boolean
    serveysRequest: boolean
    serviceUpdates: boolean
};

export type SigningMethod = 'Facebook' | 'Google' | 'Phone';


export type RiderQueryFilter = { search?: string; gender?: string, dateRange?: DateRangeValue, operatorId?: string, identityCheck?: boolean };
export type BirthdayRangeValue = { fromDate: string; toDate: string };



/* Trip */
export type Trip = {
    _id: string;
    logicalId: number;
    startPoint?: Location;
    endPoint?: Location;
    startDate?: Date;
    endDate?: Date;
    status?: string;
    dropPhoto?: TRIP_ATTACHMENT;
    operatorId?: string
    riderId?: Rider
    vehiculeId?: Vehicule
};

export type TRIP_ATTACHMENT = {
    uid: string;
    size: number;
    name: string;
    type: string;
    url: string;
};


export type Location = { latitude: number; longitude: number }

export type TripQueryFilter = { status?: string; dateRange?: DateRangeValue};
export type DateRangeValue = { fromDate: string; toDate: string };

/* Vehicule */

export type Vehicule = {
    _id: string;
    logicalId: number;
    vehiculeType?: string
    qrcode?: string;
    mileage?: number;
    status?: boolean;
    batteryLevel?: number;
    city?: string;
    powerType?: string;
    operatedDate?: string;
    location?: LocationVehicule
    operatorId?: string
    pricingId?: string
};

export type VehiculeInput = Partial<
    Omit<Vehicule, 'logicalId' | '_id'> 
>;

export type LocationVehicule = { type: string; coordinates: vehiculeCoordinates };
export type vehiculeCoordinates = { longitude: number; latitude: number };

export type VehiculeQueryFilter = { status?: boolean; cities?: string[], types?: string[], batteryLevelRange?: BatteryLevelRangeValue, dateRange?: OperatedDateRangeValue, operatorId?: string };
export type BatteryLevelRangeValue = { minLevel: number; maxLevel: number };
export type OperatedDateRangeValue = { fromDate: string; toDate: string };



/* Operator */
export type Operator = {
    _id: string;
    logicalId: number;
    name?: string;
    website?: string;
    phone?: string;
    email?: string;
    description?: string;
    tva?: string;
    type?: string;
    logo?: attachmentsUploaded;
    address?: Address;
    settingId?: Setting
};

export type OperatorQueryFilter = { search?: string; cities?: string[], types?: string[] };
export type FileUpload = { createReadStream: () => any /* Readable */; filename: string; mimetype: string };

export type OperatorInput = Partial<
    Omit<Operator, 'logicalId'> & {
        attachmentsFiles: File[] | Promise<FileUpload>[];
    }
>;

// Setting //
export type Setting = {
    _id: string;
    logicalId: number;
    fullname?: string,
    password?: string,
    email?: string,
    sectionRoles?: string[],
    operator?: Operator,
};

// City //
export type City = {
    _id: string;
    logicalId: number;
    cityName: string;
    latitude: number;
    longitude: number;
};


// Login //
export type TokenResponse = {
    accessToken?: string,
    operatorId?: string
}

export type RefrechResponse = {
    accessToken?: string,
}

export type LoginInput =  { email?: string, password?: string }

// Reset Password //
export type ResetTokenResponse = {
    resetToken?: string,
}

export type ResetPasswordRequestInput = { email?: string }

export type ResetPasswordInput = { email?: String, newPassword?: String, resetToken?: String }


// Pricing //
export type Pricing = {
    _id: string;
    logicalId: number;
    name?: string,
    note?: string,
    minutePrice?: number,
    unlockPrice?: number,
    vehiculeType?: string;
    level?: string,
};

// Pass //
export type Pass = {
    _id: string;
    logicalId: number;
    name?: string,
    description?: string,
    priceUnit?: string,
    discount?: number;
    duration?: number;
    initialPrice?: number;
    minutePrice?: number,
    unlockPrice?: number,
    vehiculeType?: string;
};

// RidePass //
export type RidePass = {
    _id: string;
    logicalId: number;
    name?: string,
    description?: string,
    priceUnit?: string,
    discount?: number;
    duration?: number;
    initialPrice?: number;
    startDate?: string,
    endDate?: string,
    vehiculeType?: string;
    minutePrice?: number,
    unlockPrice?: number,
};

/* Coupon */
export type Coupon = {
    _id: string;
    logicalId: number;
    title: string;
    description: string
    amount: number;
    reduction: number;
    code: string;
    startingDate: string;
    expirationDate: string;
    status: boolean;
    type: string;
};


export type CouponQueryFilter = { search?: string; types?: string[]; status?: boolean; dateRange?: CouponDateRangeValue };
export type CouponDateRangeValue = { fromDate: string; toDate: string };

// Campaign //
export type Campaign = {
    _id: string;
    logicalId: number;
    title?: string;
    description?: string;
    startingDate?: string;
    endDate?: string;
    status?: boolean;
};

/* Reports */
export type ReportsCounters = {
    totalTrips: number,
    totalRiders: number,
    totalMileage: number,
    totalRevenue: number,
};

export type ReportTrip = {
    year: string;
    value: number;
    category: string;
};

export type ReportCO2 = {
    month: string;
    estimate: number;
};

export type ReportsQueryFilter = { quicklyCalanderSlot?: Calendar; dateRange?: ReportsDateRangeValue, operatorId?: string };
export type ReportsDateRangeValue = { fromDate: string; toDate: string };


// Map //
export type mapCityCoordinates = { 
    longitude: number; 
    latitude: number 
};

export type LocationCityMap = {
    type: string
    coordinates: mapCityCoordinates
};

export type Coord = {
    lat: number;
    lng: number;
};

export type Zone = {
    id_zone: string
    name_zone: string
    coord: Coord[]
}; 

export type CityMap = {
    _id: string;
    logicalId?: number;
    cityName: string
    location: LocationCityMap
    zones: Zone[]
};

// Support //

export type Support = {
    email: string;
    subject: string;
    description: string;
    operatorId?: string;
};

export type SupportInput = Partial<
    Support & {
        attachmentsFiles: File[] | Promise<FileUpload>[];
    }
>;

// VehiculeProblem //

export type VehiculeProblem = {
    _id: string;
    logicalId: number,
    description: string,
    qrCode: string,
    status: string,
    problemPhotos: VehiculeProblem_Attachment[],
    currentLocation: LocationVehicule
};

export type VehiculeProblem_Attachment = {
    uid: string;
    size: number;
    name: string;
    type: string;
    url: string;
    isMainImageAttachment: boolean;
};


// welcome Contact formulaire //

export type ContactInput = {
    email: string;
    subject: string;
    firstName: string;
    description: string;
};


// invoice 

export type InvoiceAttributes = {
    logicalId: number,
    amount: number,
    status: string,
    ridingDuration: number,
    startDate: Date,
    endDate: Date,
    reference: string,
    vehiculeQrCode: string,
    distance: number,
    invoiceAttachment: InvoiceAttachment,
    tripId: Trip,
    riderId: Rider,
    couponId: Coupon,
};

export type InvoiceAttachment = {
    uid: string;
    size: number;
    name: string;
    type: string;
    url: string;
};

export type Invoice = { _id: string } & InvoiceAttributes;

// TaxiDriver //


export type TaxiDriver = {
    _id: string,
    logicalId: number,
    fullName: string,
    birthday: Date,
    email: string,
    password: string,
    phoneNumber: string,
    driverPhoto: RIDER_ATTACHMENT,
    referralCode: number,
    marque: string,
    year: string,
    immatriculation: string,
    color: string,
    idCard: string,
    numeroPermis: string,
    licenseNumber: string,
    bankName: string,
    bankAccountNumber: string,
    documentsPhotos: TaxiDriver_Attachment[],
    isDriverProfile: boolean,
    isDriverOffline: boolean;
    fcmToken: string,
    totalRides: number,
    rating: number,
    currentLocation: RIDER_Location,
    registerStatus: string,
    accountStatus: boolean,
    createdDate: Date,
};

export type TaxiDriverQueryFilterMGT = { search?: string; accountStatus?: boolean; registerStatus?: string[]; dateRange?: DateRangeValue };

export type AccountStatus =  true | false;
export enum registerStatusEnums { Pending = 'Pending', InProgress = 'In progress', Confirmed = 'Confirmed'};


export type TaxiDriver_Attachment = {
    uid: string;
    size: number;
    name: string;
    type: string;
    url: string;
};

export type TaxiDriverInput = Partial<
  Omit<TaxiDriver, '_id' | 'logicalId' >> & {
    attachmentsFiles: File[] | Promise<FileUpload>[];
}


export type TaxiDriverAndTokenResponse = {
    taxiDriver: TaxiDriver;
    accessToken: string;
};
