import { useMutation } from '@apollo/client';
import { TaxiDriver, TaxiDriverInput } from '../../shared/types';
import { CITRIN_SIGNUP_TAXI_DRIVER, SignUpDriverResponse, SignUpDriverRequest } from '..';

type CreateMutationFunction = (request: TaxiDriverInput, files: any) => Promise<any>;

export const useSignUpDriverMutation = (): { mutation: CreateMutationFunction; loading: boolean } => {
    const [mutation, { loading }] = useMutation<SignUpDriverResponse, SignUpDriverRequest>(CITRIN_SIGNUP_TAXI_DRIVER);

    const promiseMutation = async (request: TaxiDriverInput, files: any[]) => {
        const result = await mutation({
            variables: { request, files }
        });

        return result?.data?.CITRIN_APP_signUpDriver;
    };

    return {
        mutation: promiseMutation,
        loading,
    };
};