import React from "react";
import { Descriptions, Modal } from 'antd';
import { useCurrentRegisterTaxiDriver } from "../hooks/useCurrentRegisterTaxiDriver";
import { Image } from 'antd';

const RegisterTaxiDriverViewModal = ({ isOpen, onRequestClose }) => {
  const { currentRegisterTaxiDriver } = useCurrentRegisterTaxiDriver();
  const handleCancel = () => {
    onRequestClose(false);
  };
  return (
    <Modal width={600} open={isOpen} onCancel={handleCancel} footer={null} >
      <Descriptions title={`Taxi Driver details #${currentRegisterTaxiDriver?._id}`} bordered>
      <Descriptions.Item span={3} label="Driver Photo"><Image width={150} src={currentRegisterTaxiDriver?.driverPhoto?.url} /></Descriptions.Item>
      <Descriptions.Item span={3} label="license Number">{currentRegisterTaxiDriver?.licenseNumber}</Descriptions.Item>
      <Descriptions.Item span={3} label="Immatriculation">{currentRegisterTaxiDriver?.immatriculation}</Descriptions.Item>
      <Descriptions.Item span={3} label="Color">{currentRegisterTaxiDriver?.color}</Descriptions.Item>
      <Descriptions.Item span={3} label="Driver's Licence"><Image width={100} src={currentRegisterTaxiDriver?.documentsPhotos[0]?.url} /></Descriptions.Item>
      <Descriptions.Item span={3} label="Professional card"><Image width={100} src={currentRegisterTaxiDriver?.documentsPhotos[1]?.url} /></Descriptions.Item>
      <Descriptions.Item span={3} label="Gray card"><Image width={100} src={currentRegisterTaxiDriver?.documentsPhotos[2]?.url} /></Descriptions.Item>

    </Descriptions>
    </Modal>
  );
}
export default RegisterTaxiDriverViewModal;