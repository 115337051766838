import { TaxiDriver } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const RegisterTaxiDriverInfoContext = createContext<ContextType<TaxiDriver>>(null);
RegisterTaxiDriverInfoContext.displayName = "RegisterTaxiDriverInfoContext";

function useCurrentRegisterTaxiDriver() {
    const context = useContext(RegisterTaxiDriverInfoContext);
    if (!context) {
        throw new Error(`useCurrentRegisterTaxiDriver must be used within a RegisterTaxiDriverInfoProvider`);
    }

    const [currentRegisterTaxiDriver, setCurrentRegisterTaxiDriver] = context;

    return {
        currentRegisterTaxiDriver,
        setCurrentRegisterTaxiDriver
    };
}

function RegisterTaxiDriverInfoProvider(props) {
    const registerTaxiDriver = useState(initialState);
    return <RegisterTaxiDriverInfoContext.Provider value={registerTaxiDriver} {...props} />;
}

export { RegisterTaxiDriverInfoProvider, useCurrentRegisterTaxiDriver };
