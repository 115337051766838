import { useMutation } from '@apollo/client';
import { TaxiDriver } from '../../shared/types';
import { UpdateTaxiDriverResponse, UpdateTaxiDriverRequest, CITRIN_UPDATE_TAXI_DRIVER } from '..';

type UpdateMutationFunction = (request: TaxiDriver) => Promise<TaxiDriver>;
export const useUpdateTaxiDriverMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdateTaxiDriverResponse, UpdateTaxiDriverRequest>(CITRIN_UPDATE_TAXI_DRIVER);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_APP_updateDriver;
     //   updateGetVehiculeCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
